<template>
  <div class="device-index">
    <div class="container-header">
      <div class="top-actions-group">
        <div class="action-left">
          <el-form class="geology-form">
            <el-button
              type="primary"
              icon="el-icon-circle-plus-outline"
              size="medium"
              @click="onAdd"
              >新增</el-button
            >
          </el-form>
        </div>
        <div class="action-right"></div>
      </div>
    </div>

    <el-table
      class="list-table"
      ref="multipleTable"
      :data="listData"
      tooltip-effect="dark"
      style="width: 100%"
      :stripe="true"
      :border="false"
      v-loading="tableLoading"
    >
      <el-table-column label="编号" prop="Id"></el-table-column>
      <el-table-column label="角色名称" prop="Name"></el-table-column>
      <el-table-column label="描述" prop="Description"></el-table-column>
      <el-table-column label="状态" prop="EnabledMark">
        <template slot-scope="scope">
          <el-tag v-if="scope.row.EnabledMark == 0" effect="dark" size="small"
            >启用</el-tag
          >
          <el-tag
            v-if="scope.row.EnabledMark == 1"
            type="danger"
            effect="dark"
            size="small"
            >禁用</el-tag
          >
        </template>
      </el-table-column>
      <el-table-column label="创建时间" prop="CreateTime" width="180">
        <template slot-scope="scope">
          {{ scope.row.CreateTime | datetime }}
        </template>
      </el-table-column>
      <el-table-column label="操作" width="260">
        <template slot-scope="scope">
          <el-button
            type="success"
            icon="el-icon-s-operation"
            size="mini"
            @click="onSetRoleMenu(scope.row)"
            >分配权限</el-button
          >
          <el-button
            type="success"
            icon="el-icon-edit"
            size="mini"
            @click="onEdit(scope.row)"
            >修改</el-button
          >
          <el-button
            type="warning"
            icon="el-icon-error"
            size="mini"
            style="margin-left: 10px"
            @click="onListDel(scope.row)"
            >删除</el-button
          >
        </template>
      </el-table-column>
    </el-table>

    <el-dialog
      :title="formtype == 'add' ? '添加角色' : '修改角色'"
      :visible.sync="dialogFormVisible"
      width="550px"
      custom-class="geology-dialog"
      :modal-append-to-body="true"
      :destroy-on-close="true"
    >
      <el-form
        ref="form"
        class="geology-form"
        :rules="formRules"
        :model="form"
        label-width="120px"
        style="padding-top: 20px"
      >
        <el-row>
          <el-col :span="20" :offset="1">
            <el-form-item label="角色名称" prop="Name">
              <el-input v-model="form.Name" size="small"></el-input>
            </el-form-item>
            <el-form-item label="角色描述" prop="Description">
              <el-input v-model="form.Description" size="small"></el-input>
            </el-form-item>
            <el-form-item label="状态" prop="EnabledMark">
              <el-switch
                v-model="form.EnabledMark"
                :active-value="0"
                :inactive-value="1"
              >
              </el-switch>
            </el-form-item>
          </el-col>

          <el-col :span="23" :offset="1">
            <el-form-item>
              <el-button type="primary" size="medium" @click="onSubmit"
                >立即保存</el-button
              >
              <el-button
                type="primary"
                plain
                size="medium"
                style="margin-left: 10px"
                @click="dialogFormVisible = false"
                >取消</el-button
              >
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </el-dialog>

    <el-dialog
      title="设置权限"
      :visible.sync="dialogAuthorityVisible"
      width="850px"
      custom-class="geology-dialog"
      :modal-append-to-body="true"
      :destroy-on-close="true"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      @open="dialogAuthorityOpen"
    >
      <el-table
        class="list-table"
        ref="dialogAuthorityTable"
        :data="menuListData"
        tooltip-effect="dark"
        style="width: 100%"
        :stripe="true"
        :border="false"
        :default-expand-all="false"
        row-key="Id"
        :tree-props="{ children: 'children' }"
        @select="handleMenuSelect"
        @select-all="handleMenuSelectAll"
        @selection-change="handleMenuSelectionChange"
      >
        <el-table-column type="selection" width="55"> </el-table-column>
        <el-table-column
          label="名称"
          prop="Title"
          width="150"
        ></el-table-column>
        <el-table-column label="功能选项" prop="Auth">
          <template slot-scope="scope" v-if="scope.row.Auth">
              <el-checkbox
              v-model="selectAuth"
                v-for="(element, index) in getSysmenuAuthJson(scope.row.Auth)"
                :key="scope.row.Id+'_'+index"
                :label="scope.row.Id +'_'+ element.value"
                >{{ element.label }}</el-checkbox
              >
          </template>
        </el-table-column>
        <!-- <el-table-column label="菜单标识" prop="Name"></el-table-column> -->
        <el-table-column label="地址" prop="UrlAddress"></el-table-column>
      </el-table>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" size="medium" @click="onSetauthSubmit"
          >立即保存</el-button
        >
        <el-button
          type="primary"
          plain
          size="medium"
          style="margin-left: 10px"
          @click="dialogAuthorityVisible = false"
          >取消</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>
<script>
import {
  getSysmenuList,
  getSysroleList,
  delSysrole,
  addSysrole,
  editSysrole,
  getRoleSysmenu,
  setRoleSysmenu,
} from "@/api/system";
export default {
  data() {
    return {
      listData: [],
      tableLoading: false,
      dialogFormVisible: false,

      formtype: "add",
      form: {},
      formRules: {
        Name: [{ required: true, trigger: "blur", message: "请输入角色名称" }],
      },

      selectRole: "",
      dialogAuthorityVisible: false,
      menuListData: [],
      selectionMenu: [],
      selectAuth:[],
    };
  },
  components: {},
  methods: {
    getListData() {
      this.tableLoading = true;
      getSysroleList().then((resdata) => {
        this.tableLoading = false;
        if (resdata.code == 0) {
          this.listData = resdata.data;
        } else {
          this.$message({
            type: "error",
            message: resdata.msg,
          });
        }
      });
    },
    onListDel(item) {
      this.$confirm("确定要删除选择的数据吗?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          delSysrole(item.Id)
            .then((res) => {
              if (res.code == 0) {
                this.$message.success("删除成功!");
                this.getListData();
              } else {
                this.$message.error("删除失败!" + res.msg);
              }
            })
            .catch((errmsg) => {
              this.$message.error(errmsg);
            });
        })
        .catch(() => {});
    },
    onAdd() {
      this.form = {
        Description: "",
        Name: "",
        EnabledMark: 0,
      };
      this.formtype = "add";
      this.dialogFormVisible = true;
    },
    onEdit(item) {
      this.form = item;
      this.formtype = "edit";
      this.dialogFormVisible = true;
    },
    onFormClose() {
      this.getListData();
      this.dialogFormVisible = false;
    },
    onSubmit() {
      this.$refs["form"].validate((valid) => {
        if (valid) {
          if (this.formtype != "edit") {
            addSysrole(this.form)
              .then((res) => {
                if (res.code == 0) {
                  this.$message.success("保存成功!");
                  this.getListData();
                  this.dialogFormVisible = false;
                } else {
                  this.$message.error("保存失败!" + res.msg);
                }
              })
              .catch((errmsg) => {
                this.$message.error("保存失败!" + errmsg);
              });
          } else {
            editSysrole(this.form)
              .then((res) => {
                if (res.code == 0) {
                  this.$message.success("保存成功!");
                  this.getListData();
                  this.dialogFormVisible = false;
                } else {
                  this.$message.error("保存失败!" + res.msg);
                }
              })
              .catch((errmsg) => {
                this.$message.error("保存失败!" + errmsg);
              });
          }
        } else {
          return false;
        }
      });
    },
    getSysmenuList() {
      getSysmenuList().then((res) => {
        if (res.code == 0) {
          this.menuListData = res.data;
        }
      });
    },
    getSysmenuAuthJson(value){
      if(value){
        try {
          return JSON.parse(value);
        } catch (error) {
          return [];
        }
      }
      return [];
      
    },
    onSetRoleMenu(item) {
      this.selectRole = item;
      getRoleSysmenu(item.Id).then((res) => {
        if (res.code == 0) {
          this.selectionMenu = res.data;
          var that = this;
          that.selectAuth = [];
          var findActionsSelect = function(data){
            for (let index = 0; index < data.length; index++) {
              const element = data[index];
              if(element.SelectAuth){
                var auths = element.SelectAuth.split(',');
                auths.forEach(item => {
                  that.selectAuth.push(element.Id+'_'+item);
                });
              }
              if(element.children && element.children.length>0){
                findActionsSelect(element.children);
              }
            }
          }
          findActionsSelect(res.data);
          this.dialogAuthorityVisible = true;
        }
      });
    },
    dialogAuthorityOpen() {
      setTimeout(() => {
        var tableObj = this.$refs.dialogAuthorityTable;
        var menuListData = this.menuListData;
        var selectionMenu = this.selectionMenu;

        var getSelect = function (data) {
          data.forEach((item) => {
            var getmenu = function (data, id) {
              data.forEach((item) => {
                if (item.Id == id) {
                  tableObj.toggleRowSelection(item, true);
                } else {
                  if (item.children && item.children.length > 0) {
                    getmenu(item.children, id);
                  }
                }
              });
            };
            getmenu(menuListData, item.Id);
            if (item.children && item.children.length > 0) {
              getSelect(item.children);
            }
          });
        };
        getSelect(selectionMenu);
      }, 50);
    },
    handleMenuSelectionChange(rows) {
      this.selectionMenu = rows;
    },
    handleMenuSelect(selection, row) {
      var allparents = [];
      var ischecked = function () {
        var ischeck = false;
        for (let index = 0; index < selection.length; index++) {
          const element = selection[index];
          if (element == row) {
            ischeck = true;
            break;
          }
        }
        return ischeck;
      };
      var that = this;
      setTimeout(() => {
        var checked = ischecked();
        //子菜单
        if (row.children && row.children.length > 0) {
          var menudata = row.children;
          row.children.forEach((element) => {
            that.$refs.dialogAuthorityTable.toggleRowSelection(
              element,
              checked
            );
          });
        }
        //父菜单
        var parentsMenu = [];
        var findParents = function (data) {
          var ischeck = false;
          for (let index = 0; index < data.length; index++) {
            if (data[index] == row) {
              ischeck = true;
              break;
            } else {
              if (data[index].children && data[index].children.length > 0) {
                ischeck = findParents(data[index].children);
                if (ischeck) {
                  parentsMenu.push(data[index]);
                }
              }
            }
          }
          return ischeck;
        };
        if (checked) {
          findParents(that.menuListData);
          parentsMenu.forEach((element) => {
            that.$refs.dialogAuthorityTable.toggleRowSelection(
              element,
              checked
            );
          });
        }
      }, 50);
    },
    handleMenuSelectAll(selection) {
      for (let index = 0; index < selection.length; index++) {
        this.handleMenuSelect(selection, selection[index]);
      }
    },
    onSetauthSubmit() {
      var menu = [];
      var role = this.selectRole;
      console.log(this.selectAuth);
      var authArr = [];
      for (let index = 0; index < this.selectAuth.length; index++) {
        const element = this.selectAuth[index];
        var valarr = element.split('_');
        authArr.push({
          Id:valarr[0],
          Auth:valarr[1]
        });
      }
      console.log(authArr);
      var findMenuAuth = function(Id){
        var auths = [];
        authArr.forEach(element => {
          if(element.Id==Id+""){
            auths.push(element.Auth);
          }
        });
        return auths.join(',');
      }
      this.selectionMenu.forEach((element) => {
        menu.push({
          RoleId: role.Id,
          ModuleId: element.Id,
          Auth: findMenuAuth(element.Id),
        });
      });
      setRoleSysmenu(role.Id, menu)
        .then((res) => {
          if (res.code == 0) {
            this.$message.success("保存成功!");
            this.dialogAuthorityVisible = false;
          } else {
            this.$message.error("保存失败!" + res.msg);
          }
        })
        .catch((errmsg) => {
          this.$message.error("保存失败!" + errmsg);
        });
    },
  },
  created() {
    this.getListData();
    this.getSysmenuList();
  },
};
</script>